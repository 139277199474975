import React from 'react';
import { render } from 'react-dom';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import './variables.scss';
import './index.scss';
import { renderRoutes } from './components/app/routes';


const startup = (element) => {
  render(renderRoutes(), element);
};

startup(document.getElementById('sankhya'));

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React, { lazy, Suspense } from 'react';
import { urlr } from './urls';

const PublicApp = lazy(() => import('components/pages/public/App'));
const StudentApp = lazy(() => import('components/pages/student/App'));
const TeacherApp = lazy(() => import('components/pages/teacher/App'));


export const renderRoutes = () => {
  return (
    <Router>
      <Suspense
        fallback={ <div>Carregando...[Criar um componente de loading para
          colocar aqui]</div> }>
        <Switch>
          <Route path={ urlr('pub.index') } exact component={ PublicApp }/>
          <Route path={ urlr('stu.index') } exact component={ StudentApp }/>
          <Route path={ urlr('tea.index') } exact component={ TeacherApp }/>
        </Switch>
      </Suspense>
    </Router>
  );
};

import React from 'react';

const MAIN_STUDENT_URL_PREFIX = '/aluno';
const MAIN_TEACHER_URL_PREFIX = '/professor';
const MAIN_PUBLIC_URL_PREFIX = '/';

const ROUTES = {
  pub: {
    index: MAIN_PUBLIC_URL_PREFIX
  },
  stu: {
    index: MAIN_STUDENT_URL_PREFIX
  },
  tea: {
    index: MAIN_TEACHER_URL_PREFIX
  }
};


export const urlr = (name, args) => {
  try {
    if (!args) {
      return `${name}`
        .split('.').reduce((acc, part) => acc && acc[part], ROUTES);
    }
  } catch (e) {
    console.log(e);
    return '#';
  }
};
